import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { LayoutSessionComponent } from './shared/layouts/layout-session/layout-session.component';
import { SessionGuard } from './guards/session-auth.guard';
import { LayoutRestrictAreaComponent } from './shared/layouts/layout-restrict-area/layout-restrict-area.component';
import { HasPlanGuard } from './guards/has-plan.guard';
import { LayoutBlankComponent } from './shared/layouts/layout-blank/layout-blank.component';
import { environment } from '@env/environment';

const routes: Routes = [
	{
		path: 'painel',
		loadChildren: () => import('./views/private/private.module').then(m => m.PrivateModule),
		canLoad: [SessionGuard]
	},
	{
		component: LayoutSessionComponent,
		matcher: (url: UrlSegment[]) => {
			const path = url[0]?.path;

			if (!path) {
				return null;
			}

			if (
				path.startsWith('login') ||
				path.startsWith('sign-up') ||
				path.startsWith('forgot-password') ||
				path.startsWith('logout') ||
				path.startsWith('choose-plan')
			) {
				return { consumed: [] };
			}
			return null;
		},
		children: [
			{
				path: '',
				loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule)
			},
		]
	},
	{
		path: '**',
		redirectTo: environment.home
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledBlocking',
			relativeLinkResolution: 'legacy',
			scrollPositionRestoration: 'enabled',
			onSameUrlNavigation: 'reload'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
