import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { from, of } from 'rxjs';
import { initializeApp } from 'firebase/app';
import { Observable } from 'rxjs/internal/Observable';
import { map, catchError } from 'rxjs/operators';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut, UserCredential } from 'firebase/auth';


@Injectable({
	providedIn: 'root'
})
export class AmplifyService {
	constructor() {

		try {
            initializeApp(environment.firebaseConfig);
        } catch (error) {
            console.log("error");
        }
	}

	/**
	 * Validar erros
	 * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html#API_InitiateAuth_Errors
	 */
	login(username: string, password: string): Observable<UserCredential> {
		const auth = getAuth();
        return from(signInWithEmailAndPassword(auth, username, password));
	}

	isAuthenticated(): Observable<boolean> {
		const auth = getAuth();
		return new Observable<boolean>((observer) => {
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    observer.next(true);
                } else {
                    observer.next(false);
                }
                observer.complete();
            });
        }).pipe(
            catchError(err => of(false))
        );
	}

	logout(): Observable<void> {
		const auth = getAuth();
		return from(signOut(auth));
	}

	getJwt(): Observable<string | null> {
		const auth = getAuth();
		const user = auth.currentUser;
		if (user) {
			return from(user.getIdToken()).pipe(
				map(token => token),  // Aqui você obtém o JWT do Firebase
				catchError(err => of(null))
			);
		}
		return of(null);
	}
}
